<template>
  <div>
    <v-container>
      <p class="mb-4">
        A solution is created when a small amount of [A] dissolves in a large amount of [B]. When a
        solution's concentration is reported in moles of [C] per liter of solution, the
        concentration is reported as a [D]. When a solution's concentration is reported in grams of
        [E] per [F] of solution, the concentration (when expressed as a percentage) is reported as a
        [G]. The solution concentration would be the [H] variable because the [I]. Therefore, the
        concentration value will be plotted on the [J]-axis.
      </p>

      <p class="mb-1">
        [A]:
        <v-select
          v-model="inputs.question1Value"
          :items="options.question1"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 319px"
          label="Select an option:"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        [B]:
        <v-select
          v-model="inputs.question2Value"
          :items="options.question2"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 319px"
          label="Select an option:"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        [C]:
        <v-select
          v-model="inputs.question3Value"
          :items="options.question3"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 319px"
          label="Select an option:"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        [D]:
        <v-select
          v-model="inputs.question4Value"
          :items="options.question4"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 319px"
          label="Select an option:"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        [E]:
        <v-select
          v-model="inputs.question5Value"
          :items="options.question5"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 319px"
          label="Select an option:"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        [F]:
        <v-select
          v-model="inputs.question6Value"
          :items="options.question6"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 319px"
          label="Select an option:"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        [G]:
        <v-select
          v-model="inputs.question7Value"
          :items="options.question7"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 319px"
          label="Select an option:"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        [H]:
        <v-select
          v-model="inputs.question8Value"
          :items="options.question8"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 319px"
          label="Select an option:"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        [I]:
        <v-select
          v-model="inputs.question9Value"
          :items="options.question9"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 319px"
          label="Select an option:"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        [J]:
        <v-select
          v-model="inputs.question10Value"
          :items="options.question10"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 319px"
          label="Select an option:"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>
    </v-container>
  </div>
</template>

<script>
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';

export default {
  name: 'UCIrvineQuiz1S1Q4',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        question1Value: null,
        question2Value: null,
        question3Value: null,
        question4Value: null,
        question5Value: null,
        question6Value: null,
        question7Value: null,
        question8Value: null,
        question9Value: null,
        question10Value: null,
      },
      options: {
        question1: [
          {
            text: 'solute',
            value: 'answer1',
          },
          {
            text: 'solvent',
            value: 'answer2',
          },
        ],
        question2: [
          {
            text: 'solute',
            value: 'answer1',
          },
          {
            text: 'solvent',
            value: 'answer2',
          },
        ],
        question3: [
          {
            text: 'solute',
            value: 'answer1',
          },
          {
            text: 'solvent',
            value: 'answer2',
          },
        ],
        question4: [
          {
            text: 'molality',
            value: 'answer1',
          },
          {
            text: 'molarity',
            value: 'answer2',
          },
          {
            text: 'density',
            value: 'answer3',
          },
          {
            text: 'mass%',
            value: 'answer4',
          },
        ],
        question5: [
          {
            text: 'solute',
            value: 'answer1',
          },
          {
            text: 'solvent',
            value: 'answer2',
          },
        ],
        question6: [
          {
            text: 'litres',
            value: 'answer1',
          },
          {
            text: 'millilitres',
            value: 'answer2',
          },
          {
            text: 'moles',
            value: 'answer3',
          },
          {
            text: 'mole fraction of solvent',
            value: 'answer4',
          },
        ],
        question7: [
          {
            text: 'molality',
            value: 'answer1',
          },
          {
            text: 'molarity',
            value: 'answer2',
          },
          {
            text: 'w/v%',
            value: 'answer3',
          },
          {
            text: 'mass%',
            value: 'answer4',
          },
        ],
        question8: [
          {
            text: 'independent',
            value: 'answer1',
          },
          {
            text: 'dependent',
            value: 'answer2',
          },
          {
            text: 'constant',
            value: 'answer3',
          },
        ],
        question9: [
          {
            text: 'experimenter has direct control over its value',
            value: 'answer1',
          },
          {
            text: 'value changes because of the manipulation of another variable',
            value: 'answer2',
          },
          {
            text: 'value remains constant',
            value: 'answer3',
          },
        ],
        question10: [
          {
            text: 'x',
            value: 'answer1',
          },
          {
            text: 'y',
            value: 'answer2',
          },
        ],
      },
    };
  },
};
</script>
