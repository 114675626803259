var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('p',{staticClass:"mb-4"},[_vm._v(" A solution is created when a small amount of [A] dissolves in a large amount of [B]. When a solution's concentration is reported in moles of [C] per liter of solution, the concentration is reported as a [D]. When a solution's concentration is reported in grams of [E] per [F] of solution, the concentration (when expressed as a percentage) is reported as a [G]. The solution concentration would be the [H] variable because the [I]. Therefore, the concentration value will be plotted on the [J]-axis. ")]),_c('p',{staticClass:"mb-1"},[_vm._v(" [A]: "),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"319px"},attrs:{"items":_vm.options.question1,"label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question1Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question1Value", $$v)},expression:"inputs.question1Value"}})],1),_c('p',{staticClass:"mb-1"},[_vm._v(" [B]: "),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"319px"},attrs:{"items":_vm.options.question2,"label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question2Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question2Value", $$v)},expression:"inputs.question2Value"}})],1),_c('p',{staticClass:"mb-1"},[_vm._v(" [C]: "),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"319px"},attrs:{"items":_vm.options.question3,"label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question3Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question3Value", $$v)},expression:"inputs.question3Value"}})],1),_c('p',{staticClass:"mb-1"},[_vm._v(" [D]: "),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"319px"},attrs:{"items":_vm.options.question4,"label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question4Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question4Value", $$v)},expression:"inputs.question4Value"}})],1),_c('p',{staticClass:"mb-1"},[_vm._v(" [E]: "),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"319px"},attrs:{"items":_vm.options.question5,"label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question5Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question5Value", $$v)},expression:"inputs.question5Value"}})],1),_c('p',{staticClass:"mb-1"},[_vm._v(" [F]: "),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"319px"},attrs:{"items":_vm.options.question6,"label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question6Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question6Value", $$v)},expression:"inputs.question6Value"}})],1),_c('p',{staticClass:"mb-1"},[_vm._v(" [G]: "),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"319px"},attrs:{"items":_vm.options.question7,"label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question7Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question7Value", $$v)},expression:"inputs.question7Value"}})],1),_c('p',{staticClass:"mb-1"},[_vm._v(" [H]: "),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"319px"},attrs:{"items":_vm.options.question8,"label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question8Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question8Value", $$v)},expression:"inputs.question8Value"}})],1),_c('p',{staticClass:"mb-1"},[_vm._v(" [I]: "),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"319px"},attrs:{"items":_vm.options.question9,"label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question9Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question9Value", $$v)},expression:"inputs.question9Value"}})],1),_c('p',{staticClass:"mb-1"},[_vm._v(" [J]: "),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"319px"},attrs:{"items":_vm.options.question10,"label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.question10Value),callback:function ($$v) {_vm.$set(_vm.inputs, "question10Value", $$v)},expression:"inputs.question10Value"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }